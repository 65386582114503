import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "slider";

var sliderService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    sliderTanimlama(resim, baslik, altBaslik, aciklama, linkButon1, link1, linkButon2, link2, durum, sliderKonum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            resim: resim,
            baslik: baslik,
            altBaslik: altBaslik,
            aciklama: aciklama,
            linkButon1: linkButon1,
            link1: link1,
            linkButon2: linkButon2,
            link2: link2,
            durum: durum,
            sliderKonum,
            siraNo: 1,
            serviceName: moduleName,
            methodName: "sliderEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    sliderList(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            methodName: "sliderListele",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    sliderSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            methodName: "sliderSayisi",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    sliderAktifYap(sliderID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sliderID: sliderID,
            durum: 1,
            methodName: "sliderAktifPasifEt",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    sliderDurumGuncelle(sliderID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sliderID,
            durum,
            methodName: "sliderAktifPasifEt",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    sliderPasifYap(sliderID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sliderID: sliderID,
            durum: 0,
            methodName: "sliderAktifPasifEt",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },


    //$data['sliderID'],$data['kategori'],$data['baslik'],$data['altBaslik'], $data['aciklama'], $data['linkButon1'], $utility->linkTemizle($data['link1']),$data['linkButon2'],$utility->linkTemizle($data['link2']),$data['durum'],$simdi,$data['enBaslik'],$data['enAltBaslik'],$data['enAciklama'],$data['enLinkButon1'],$data['enLinkButon2'],$upload['dosyaAdi'],$uploadEn['dosyaAdi'],$data['siraNo'],$yetkili, $ip1, $ip2, $ip3,"panel"
    sliderGuncelle(sliderID, resim, baslik, altBaslik, aciklama, linkButon1, link1, linkButon2, link2, durum, sliderKonum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            sliderID: sliderID,
            resim: resim,
            baslik: baslik,
            altBaslik: altBaslik,
            aciklama: aciklama,
            linkButon1: linkButon1,
            link1: link1,
            linkButon2: linkButon2,
            link2: link2,
            durum: durum,
            sliderKonum,
            siraNo: 1,
            serviceName: moduleName,
            methodName: "sliderGuncelle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    sliderDetay(sliderID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            sliderID: sliderID,
            methodName: "sliderDetay",
            serviceName: moduleName
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    }

}

export default sliderService